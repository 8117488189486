import React from 'react';

export const Logo = () => {
  const anchorStyle = {
    display: 'flex',
    alignItems: 'center',
    color: '#fff'
  };
  const svgStyle = {
    marginRight: '12px'
  };
  return (
    <div>
      <a
        style={anchorStyle}
        href="https://foursquare.com/products/unfolded/"
        target="_blank"
        rel="noreferrer"
      >
        <svg
          width="30"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 275.9 275.9"
          style={svgStyle}
        >
          <rect x="0.1" y="0.3" width="275.4" height="275.6" />
          <g>
            <path
              fill="#FFFFFF"
              d="M69.2,50.1H121v8.5H77.8v23.8h38.4v8.5H77.8v34.4h-8.6C69.2,125.2,69.2,50.1,69.2,50.1z"
            />
            <path
              fill="#FFFFFF"
              d="M67.7,198.8l8.4-1.9c1.5,10.4,8.7,16.6,20.4,16.6c10.5,0,18.8-4.9,18.8-12.9c0-5.7-4.2-10.5-20.6-15.4
		c-18.6-5.3-24.8-12.1-24.8-22.1c0-12.9,10.4-19.8,25.5-19.8c16.9,0,24.4,8.6,27,20.4l-8.4,1.9c-2.1-9.7-8.7-13.8-18.9-13.8
		c-9.6,0-16.4,3.6-16.4,10.5c0,5.6,4.4,9.9,19.6,14.7c18.1,5.6,25.9,11.7,25.9,23.3c0,14.4-12.1,21.9-27.5,21.9
		C80.6,222.1,69.6,213.8,67.7,198.8z"
            />
            <path
              fill="#FFFFFF"
              d="M134.5,182.9c0-22.3,14.6-39.7,37-39.7c22.3,0,36.7,17.5,36.7,39.7c0,11.1-3.7,20.7-9.9,27.7
		c3,3,5.9,6,8.8,9.2l-6.2,6c-3-3.2-6.1-6.4-9.2-9.5c-5.7,3.7-12.5,5.8-20.2,5.8C149.9,222.1,134.5,205.6,134.5,182.9z M185.2,209.9
		c-2.9-2.8-5.8-5.5-8.8-8.1l6-6.1c3.2,2.8,6.4,5.7,9.4,8.6c4.5-5.4,7.2-12.8,7.2-21.5c0-17.6-10.7-31-27.5-31s-27.6,13.4-27.6,31
		c0,18.1,11.7,30.8,27.6,30.8C176.6,213.6,181.2,212.4,185.2,209.9z"
            />
          </g>
        </svg>
        Foursquare Studio Panel
      </a>
    </div>
  );
};
