import {PanelPlugin} from '@grafana/data';
import {DocsLink} from 'components/docsLinkComponent';
import {SaveMapConfig} from 'components/saveMapConfigComponent';
import {Logo} from 'components/logoComponent';
import {StudioPanelOptions} from './types';
import {StudioMapPanel} from './studioMapPanel';

export const plugin = new PanelPlugin<StudioPanelOptions>(StudioMapPanel).setPanelOptions(
  (builder) => {
    return builder
      .addCustomEditor({
        id: 'productLogo',
        path: 'productLogo',
        name: '',
        editor: Logo
      })
      .addCustomEditor({
        id: 'saveMapConfig',
        path: 'saveMapConfig',
        name: 'Save map configuration',
        editor: SaveMapConfig,
        defaultValue: false
      })
      .addBooleanSwitch({
        path: 'createLinePath',
        name: 'Create line path from points',
        defaultValue: false
      })
      .addBooleanSwitch({
        path: 'animateTrips',
        name: 'Enable animation',
        defaultValue: false,
        showIf: (config) => config.createLinePath
      })
      .addCustomEditor({
        id: 'docsLink',
        path: 'docsLink',
        name: '',
        editor: DocsLink
      });
  }
);
