import React, {useState, useEffect} from 'react';
import {DataFrame, PanelProps} from '@grafana/data';
import {StudioPanelOptions} from 'types';
import {MapApi} from '@unfolded/map-sdk';

import {StudioMapComponent} from 'components/studioMapComponent';
import {SaveMapComponent} from 'components/saveMapComponent';
import {processDataFrame} from 'utils/process-data';

interface Props extends PanelProps<StudioPanelOptions> {}

export const StudioMapPanel: React.FC<Props> = ({
  options,
  onOptionsChange,
  data,
  width,
  height
}) => {
  const [mapInstance, setMapInstance] = useState<MapApi>();

  useEffect(() => {
    if (options.saveMapConfig) {
      const mapConfig = mapInstance?.getMapConfig();
      // Update values (options) in the Grafana state
      mapConfig &&
        onOptionsChange({...options, saveMapConfig: false, mapConfig: JSON.stringify(mapConfig)});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.saveMapConfig]);

  useEffect(() => {
    mapInstance && mapUpdate(mapInstance, options, data.series, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.createLinePath, options.animateTrips]);

  // Run on every options change or query update
  mapInstance && mapUpdate(mapInstance, options, data.series);

  return (
    <div
      style={{
        position: 'relative',
        width: `${width}px`,
        height: `${height}px`
      }}
    >
      <StudioMapComponent setMap={setMapInstance} height={height} options={options} />
      <SaveMapComponent
        mapInstance={mapInstance}
        dataFrame={data.series}
        createLinePath={options.createLinePath}
        animateTrips={options.animateTrips}
      />
    </div>
  );
};

/**
 * Runs on every options change or query update
 * Removes and re-adds the data through the Map SDK with layer creation
 * @param mapInstance Studio Map SDK map object
 * @param options Grafana panel options
 * @param dataFrames Grafana dataframe objects array. See: https://grafana.com/docs/grafana/latest/developers/plugins/data-frames/
 * @param removeDataset If line string creation is activated/deactivated remove the dataset with layers first
 */
const mapUpdate = (
  mapInstance: MapApi,
  options: StudioPanelOptions,
  dataFrames: DataFrame[],
  removeDataset = false
) => {
  const processedData = processDataFrame(dataFrames, options.createLinePath, options.animateTrips);
  if (removeDataset) {
    for (let i = 0; i < processedData.length; i++) {
      if (mapInstance.getDatasetById(`grafana-query-${i}`)) {
        mapInstance?.removeDataset(`grafana-query-${i}`);
      }
    }
  }
  addDataToMap(mapInstance, processedData);
};

/**
 * Adds processed data from each query to the map and creates layers
 * @param mapInstance Studio Map SDK map object
 * @param processedData Array of data in the proper format for the Studio Map SDK
 */
const addDataToMap = (mapInstance: MapApi, processedData: any[]) => {
  processedData.forEach((dataItem, index) => {
    if (mapInstance.getDatasetById(`grafana-query-${index}`)) {
      mapInstance.replaceDataset(`grafana-query-${index}`, {
        id: `grafana-query-${index}`,
        label: `Grafana Query ${index + 1}`,
        data: dataItem
      });
    } else {
      mapInstance?.addDataset({
        id: `grafana-query-${index}`,
        label: `Grafana Query ${index + 1}`,
        data: dataItem
      });
    }
  });
};
