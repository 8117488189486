import {DataFrame, DataFrameView} from '@grafana/data';

interface LinePoint {
  latitude: number;
  longitude: number;
  time: number;
  altitude?: number;
}

/**
 * Returns the data in the proper format for the Studio Map SDK
 * @param options Values provided from Grafana options panel
 * @param dataFrame Grafana dataframe object. See: https://grafana.com/docs/grafana/latest/developers/plugins/data-frames/
 */
export const processDataFrame = (
  dataFrames: DataFrame[],
  createLinePath: boolean,
  animateTrips: boolean
) => {
  const processedData: any[] = [];

  dataFrames.forEach((dataFrame) => {
    const view = new DataFrameView(dataFrame);
    const isGeoJSON = dataFrame.fields.find((field) => field.name === 'geojson_layer');
    if (isGeoJSON) {
      const geoJSONData = JSON.parse(view.get(0)['geojson_layer']);
      processedData.push(geoJSONData);
    } else {
      let dataToAdd: any = [];
      view.forEach((row) => {
        let dataRow: any = {};
        for (const propertyName in row) {
          dataRow[propertyName] = row[propertyName];
        }
        dataToAdd.push(dataRow);
      });

      if (createLinePath) {
        dataToAdd = generateLineString(animateTrips, dataToAdd);
      }
      processedData.push(dataToAdd);
    }
  });

  return processedData;
};

/**
 * Generates a basic GeoJSON LineString or adds time dimension for the TripLayer
 * @param animateTrips Option value provided from the Grafana panel options
 * @param data Processed data received from the DataFrame
 */
const generateLineString = (animateTrips: boolean, data: LinePoint[]) => {
  const pointArray = data.map((item: LinePoint) => {
    if (animateTrips) {
      return [item.longitude, item.latitude, item.altitude ? item.altitude : 0, item.time];
    }
    return [item.longitude, item.latitude];
  });

  const geojson: any = {
    name: 'NewFeatureType',
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: pointArray
        },
        properties: null
      }
    ]
  };

  return geojson;
};
