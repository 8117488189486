import React from 'react';

export const DocsLink = () => {
  const linkColor = '#5558db';
  const anchorStyle = {
    display: 'flex',
    alignItems: 'center',
    color: linkColor
  };
  const iconStyle = {
    width: '16px',
    height: '16px',
    marginRight: '12px'
  };
  return (
    <div>
      <a
        style={anchorStyle}
        href="https://docs.unfolded.ai/integrations/grafana"
        target="_blank"
        rel="noreferrer"
      >
        <svg style={iconStyle}>
          <path
            style={{fill: linkColor}}
            d="M4.96875 4.95312V4.01562C4.96875 3.88672 5.07422 3.78125 5.20312 3.78125H11.2969C11.4258 3.78125 11.5312 3.88672 11.5312 4.01562V4.95312C11.5312 5.08203 11.4258 5.1875 11.2969 5.1875H5.20312C5.07422 5.1875 4.96875 5.08203 4.96875 4.95312ZM5.20312 7.53125H11.2969C11.4258 7.53125 11.5312 7.42578 11.5312 7.29688V6.35938C11.5312 6.23047 11.4258 6.125 11.2969 6.125H5.20312C5.07422 6.125 4.96875 6.23047 4.96875 6.35938V7.29688C4.96875 7.42578 5.07422 7.53125 5.20312 7.53125ZM13.9658 12.21C13.8428 12.5908 13.8428 13.7217 13.9658 14.1025C14.1797 14.1436 14.3438 14.334 14.3438 14.5625V15.0312C14.3438 15.2891 14.1328 15.5 13.875 15.5H3.5625C2.26758 15.5 1.21875 14.4512 1.21875 13.1562V2.84375C1.21875 1.54883 2.26758 0.5 3.5625 0.5H13.875C14.1328 0.5 14.3438 0.710937 14.3438 0.96875V11.75C14.3438 11.9785 14.1826 12.166 13.9658 12.21ZM12.7617 12.2188H3.5625C3.04687 12.2188 2.625 12.6406 2.625 13.1562C2.625 13.6748 3.04395 14.0938 3.5625 14.0938H12.7617C12.6826 13.5869 12.6826 12.7256 12.7617 12.2188ZM12.9375 1.90625H3.5625C3.04395 1.90625 2.625 2.3252 2.625 2.84375V11.0088C2.91211 10.8828 3.22852 10.8125 3.5625 10.8125H12.9375V1.90625Z"
          />
        </svg>
        Documentation
      </a>
    </div>
  );
};
