import React from 'react';
import {PanelContainer, Button, Label} from '@grafana/ui';
import {StandardEditorProps} from '@grafana/data';

export const SaveMapConfig: React.FC<StandardEditorProps<boolean>> = ({value, onChange}) => {
  return (
    <PanelContainer>
      <Label style={{color: 'rgba(204, 204, 220, 0.65)'}}>
        Clicking here you will save the current map configuration. For this change to be applied in
        your dashboard you also have to click Save button in the upper right corner in Grafana.
      </Label>
      <Button onClick={() => onChange(!value)}>Save settings</Button>
    </PanelContainer>
  );
};
