import {createMap, MapApi} from '@unfolded/map-sdk';
import React, {useRef, useEffect} from 'react';
import {StudioPanelOptions} from 'types';

export const StudioMapComponent = ({
  setMap,
  options,
  height
}: {
  setMap: (map: MapApi) => void;
  height: number;
  options: StudioPanelOptions;
}) => {
  const mountContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadMap = async () => {
      const mapInstance = await createMap({});
      if (mapInstance) {
        mountContainerRef?.current && mapInstance.addToDOM(mountContainerRef.current);
        options.mapConfig && mapInstance?.setMapConfig(JSON.parse(options.mapConfig));
        setMap(mapInstance);
      }
    };
    loadMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMap]);

  return (
    <div className="studio-plugin">
      <div style={{height: `${height}px`}} ref={mountContainerRef} />
    </div>
  );
};

export default StudioMapComponent;
